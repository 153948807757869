import { Grid, List } from '@material-ui/core';
import React from 'react';
import { ParentField } from '.';

const ParentList = ({ record: { parents } }) => {
  return (
    <Grid item xs={12}>
      <List>
        {parents.map((e, i) => (
          <ParentField key={i} record={e} />
        ))}
      </List>
    </Grid>
  );
};

export default ParentList;
