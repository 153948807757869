import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Create, Loading, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import { AdminApi, Api } from '../../utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  saveButton: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
}));

const AssignmentCreate = (props) => {
  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});

  useEffect(() => {
    Api.get('/list/entrance_grades').then((response) => {
      if (response.status === 200) setGrades(response.data);
      setLoading(false);
    });
  }, []);

  const save = async () => {
    setSaving(true);
    try {
      const result = await AdminApi.post('/assignment/new', {
        grade,
        name,
        content,
      });
      setSaving(false);
      redirect('/assignments');
      notify(result.data.message);
    } catch (error) {
      setSaving(false);
      setError(error.response.data['0']);
    }
  };

  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  return (
    <Create {...props} title="Créer une épreuve">
      <Card className={classes.root}>
        <CardHeader title="Créer une épreuve" />
        <CardContent>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <TextField
                  label="Titre"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  error={!!error['data.name']}
                  helperText={error['data.name']}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!error['data.grade']}
                >
                  <InputLabel id="gradeLabel">Niveau</InputLabel>
                  <Select
                    labelId="gradeLabel"
                    id="grade"
                    value={grade}
                    onChange={handleChange}
                    label="Niveau"
                  >
                    {grades.map(({ id, name }) => (
                      <MenuItem value={id}>{name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error['data.grade']}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Contenu"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  value={content}
                  onChange={(event) => setContent(event.target.value)}
                  error={!!error['data.content']}
                  helperText={error['data.content']}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.saveButton}
          >
            Enregistrer
            {saving && (
              <Box className={classes.progress}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={16}
                />
              </Box>
            )}
          </Button>
        </CardActions>
      </Card>
    </Create>
  );
};

export default AssignmentCreate;
