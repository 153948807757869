import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUpdate, useNotify } from 'react-admin';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';
import { AdminApi } from '../utils/Api';
import LongPressButton from './LongPressButton';
import JoditEditor from 'jodit-react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '&>:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.getContrastText(
      theme.palette.primary.main
    )} !important`,
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ChangeState = ({
  record: { id, status: recordStatus, application_id, step, special_behaviour },
}) => {
  const classes = useStyles();

  const [status, setStatus] = useState(recordStatus);
  const [reviewStep, setReviewStep] = useState('');
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [reason, setReason] = useState('');

  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 400,
    width: '100%',
    placeholder: 'Commencer par saisir quelque chose...',
  };

  const notify = useNotify();

  const [setStatusToInProgress, { error: inProgressError }] = useUpdate(
    'states',
    application_id,
    { status: 1 },
    { status: 0 }
  );

  useEffect(() => {
    if (inProgressError) notify(inProgressError.toString());
  }, [inProgressError, notify]);

  const [setStatusToCompleted, { error: completedError }] = useUpdate(
    'states',
    application_id,
    { status: step === 6 ? 6 : 2 },
    { status: 1 }
  );

  useEffect(() => {
    if (completedError) notify(completedError.toString());
  }, [completedError, notify]);

  const handleRevokeModalClose = (confirmed) => {
    setOpenRevokeModal(false);
    if (confirmed) {
      if (!reason.length) notify('Raison vide');
      else
        AdminApi.post(
          `${process.env.REACT_APP_ADMIN_URL}/applicant/${id}/review`,
          {
            status: 3,
            step: reviewStep,
            reason,
          }
        )
          .then(({ status, data }) => {
            if (status === 400) return notify(data.message);

            setStatus(3);
          })
          .catch(() => {
            notify("Erreur de l'action");
          });
    }
    setReason('');
  };

  const setStatusToReceived = () => {
    AdminApi.post(`/applicant/${id}/received`)
      .then(({ status, data }) => {
        if (status === 400) return notify(data.message);

        setStatus(4);
      })
      .catch(() => {
        notify("Erreur de l'action");
      });
  };

  const setStatusToNotReceived = () => {
    AdminApi.post(`/applicant/${id}/not-received`)
      .then(({ status, data }) => {
        if (status === 400) return notify(data.message);

        setStatus(5);
        notify('Email envoyé');
      })
      .catch(() => {
        notify("Erreur de l'action");
      });
  };

  return (
    <div className={classes.root}>
      <LongPressButton
        startIcon={status === 1 ? <Check /> : null}
        className={clsx({ [classes.active]: status === 1 })}
        variant="contained"
        onLongPress={() =>
          setStatusToInProgress(
            {},
            {
              undoable: true,

              onSuccess: (_) => {
                setStatus(1);
                notify('Status modifié', 'info', {}, true);
              },
            }
          )
        }
      >
        En cours
      </LongPressButton>
      <LongPressButton
        startIcon={status === 2 ? <Check /> : null}
        className={clsx({ [classes.active]: status === 2 })}
        variant="contained"
        onLongPress={() =>
          setStatusToCompleted(
            {},
            {
              onSuccess: (_) => {
                setStatus(2);
                notify('Status modifié', 'info', {}, true);
              },
            }
          )
        }
      >
        Validé
      </LongPressButton>
      <LongPressButton
        variant="contained"
        className={clsx({ [classes.active]: status === 3 })}
        onLongPress={() => setOpenRevokeModal(true)}
      >
        Renvoyer
      </LongPressButton>
      <LongPressButton
        variant="contained"
        className={clsx({ [classes.active]: status === 4 })}
        onLongPress={setStatusToReceived}
      >
        Reçu au concours
      </LongPressButton>
      <LongPressButton
        variant="contained"
        className={clsx({ [classes.active]: status === 5 })}
        onLongPress={setStatusToNotReceived}
      >
        Non reçu au concours
      </LongPressButton>
      <Dialog
        open={openRevokeModal}
        onClose={handleRevokeModalClose}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle disableTypography className={classes.modalTitle}>
          <Typography component="h2" variant="h6">
            Renvoyer la candidature
          </Typography>
          <FormControl>
            <InputLabel id="stepLabel">Étape</InputLabel>
            <Select
              labelId="stepLabel"
              id="step"
              value={reviewStep}
              onChange={(e) => setReviewStep(e.target.value)}
              style={{ width: 150 }}
            >
              <MenuItem value="" disabled>
                Séléctionner une étape
              </MenuItem>
              <MenuItem value={1}>Étape 1</MenuItem>
              <MenuItem value={2}>Étape 2</MenuItem>
              <MenuItem value={3}>Étape 3</MenuItem>
              {!special_behaviour && <MenuItem value={4}>Étape 4</MenuItem>}
            </Select>
          </FormControl>
        </DialogTitle>
        <DialogContent>
          <JoditEditor
            ref={editor}
            value={reason}
            config={config}
            onBlur={() => {
              setReason(editor.current.value);
              editor.current.focus();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={reviewStep === ''}
            onClick={() => handleRevokeModalClose(true)}
            color="primary"
          >
            Confirmer
          </Button>
          <Button
            onClick={() => handleRevokeModalClose(false)}
            color="primary"
            autoFocus
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeState;
