import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { AdminApi } from '../../utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    '& [class^=RaLoading-container]': {
      height: 400,
    },
  },
}));

const StatisticPage = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    setLoading(true);
    AdminApi.get('/statistic/step').then(({ data: { 0: datas } }) => {
      const newData = [...data];
      datas.forEach((e) => {
        newData[e.step - 1] = e.number;
      });
      setData(newData);
      setLoading(false);
    });
  }, []);

  return (
    <Card className={classes.root}>
      <CardHeader title="Statistiques" />
      <CardContent>
        {loading ? (
          <Loading />
        ) : (
          <Bar
            data={{
              datasets: [
                {
                  barPercentage: 0.5,
                  barThickness: 'flex',
                  backgroundColor: '#00aaff',
                  data,
                  label: 'Nombre de candidats',
                },
              ],
              labels: [
                'Étape 1',
                'Étape 2',
                'Étape 3',
                'Étape 4',
                'Étape 5',
                'Étape 6',
              ],
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default StatisticPage;
