import { Box } from '@material-ui/core';
import React from 'react';
import {
  ListButton,
  Show,
  TopToolbar,
  ReferenceField,
  TextField,
} from 'react-admin';
import { ChangeState, DocumentList, ParentList, ShowLayout } from '../../components';

const OldRegisterShowActions = ({ basePath, data: record }) => (
  <TopToolbar>
    <ChangeState record={record} />
    <Box width={20} />
    <ListButton basePath={basePath} />
    {/*<EditButton basePath={basePath} record={data}/>*/}
  </TopToolbar>
);

const OldRegisterShow = (props) => {
  return (
    <Show
      {...props}
      actions={<OldRegisterShowActions />}
      title={<TextField source="name" label="Nom" />}
    >
      <ShowLayout>
        <ReferenceField
          label="Cursus"
          source="cursus_id"
          reference="cursus"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Niveau"
          source="grade_id"
          reference="grades"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <div />
        <div />
        <ReferenceField
          label="Nom"
          source="person_id"
          reference="persons"
          link={false}
        >
          <TextField source="lastname" />
        </ReferenceField>
        <ReferenceField
          label="Prénom"
          source="person_id"
          reference="persons"
          link={false}
        >
          <TextField source="firstname" />
        </ReferenceField>
        <ReferenceField
          label="Email"
          source="user_id"
          reference="users"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Nom d'usage"
          source="person_id"
          reference="persons"
          link={false}
        >
          <TextField source="usage_name" />
        </ReferenceField>
        <ReferenceField
          label="Numéro de téléphone"
          source="person_id"
          reference="persons"
          link={false}
        >
          <TextField source="mobile_phone" />
        </ReferenceField>
        <ReferenceField
          label="Adresse"
          source="person_id"
          reference="persons"
          link={false}
        >
          <TextField source="address" />
        </ReferenceField>
        <ParentList />
        <DocumentList label="Documents" />
      </ShowLayout>
    </Show>
  );
};

export default OldRegisterShow;
