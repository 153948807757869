import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TopToolbar,
  CreateButton,
  TextField,
  EditButton,
  useListContext,
  sanitizeListRestProps,
  useRefresh,
  useNotify,
} from 'react-admin';
import { AdminApi } from '../../utils/Api';

const DeleteButton = (props) => {
  const refresh = useRefresh();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const notify = useNotify();

  const deleteGrade = () => {
    const {
      record: { id },
    } = props;
    setOpenDeleteConfirmation(false);
    AdminApi.delete(`/grade/${id}`)
      .then(() => {
        notify('Niveau supprimé');
        refresh();
      })
      .catch(() => {
        notify('Erreur');
      });
  };

  return (
    <>
      <Button
        onClick={() => setOpenDeleteConfirmation(true)}
        style={{ color: 'red' }}
      >
        <DeleteIcon style={{ marginRight: '0.5em' }} />
        <span>Supprimer</span>
      </Button>
      <Dialog
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Supprimer ce niveau?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de supprimer un niveau. <br />
            Tous candidats ayant choisi le dit niveau se verra être dépourvu de
            celui-ci
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteGrade} color="primary">
            Supprimer
          </Button>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color="primary"
            autoFocus
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GradeActions = (props) => {
  const { className, ...rest } = props;
  const { basePath } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} label="Ajouter un niveau" />
    </TopToolbar>
  );
};

const GradeList = (props) => (
  <List
    {...props}
    title="Liste des niveaux"
    sort={{ field: 'name', order: 'ASC' }}
    actions={<GradeActions />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" label="Titre" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default GradeList;
