import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import {
  Loading,
  useNotify,
  useRedirect,
  useEditController,
  Edit,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import 'moment/locale/fr';
import * as moment from 'moment';
import { AdminApi, Api } from '../../utils/Api';
import { getDateFromString } from '../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  saveButton: {
    position: 'relative',
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
}));

const ExamSessionEdit = (props) => {
  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const [id, setId] = useState();
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [deadlineDate, setDeadlineDate] = useState(new Date());
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(true);
  const [schoolYears, setSchoolYears] = useState([]);
  const [schoolYear, setSchoolYear] = useState('');
  const [loadingFetch, setLoadingFetch] = useState(false);

  const save = () => {
    setSaving(true);
    AdminApi.put(`/entrance_exam_session/${id}`, {
      name,
      start: moment(startDate).format('yyyy-MM-DD HH:mm:ss'),
      end: moment(endDate).format('yyyy-MM-DD HH:mm:ss'),
      deadline: moment(deadlineDate).format('yyyy-MM-DD HH:mm:ss'),
      subscription_enabled: subscriptionEnabled ? 1 : 0,
      school_year: schoolYear,
    })
      .then(() => {
        notify('Succès');
        redirect('/entrance_exam_sessions');
      })
      .catch(
        ({
          response: {
            data: { 0: error },
          },
        }) => {
          setError(error);
          setSaving(false);
          notify("Erreur de l'opération");
        }
      );
  };

  const { loading, record } = useEditController(props);

  useEffect(() => {
    if (loading) {
      setLoadingFetch(true);

      Api.get('/list/school_years')
        .then(({ data }) => {
          setSchoolYears(data);
          setLoadingFetch(false);
        })
        .catch((error) => {
          setError(error);
          setLoadingFetch(false);
          notify('Erreur lors du chargement: ' + error.toString());
        });
    }
  }, [notify, loading]);

  useEffect(() => {
    if (record) {
      const {
        id,
        name,
        start,
        end,
        deadline,
        school_year: { id: schoolYearId },
        subscription_enabled,
      } = record;

      setId(id);
      setName(name);
      setStartDate(getDateFromString(start));
      setEndDate(getDateFromString(end));
      setDeadlineDate((deadline && getDateFromString(deadline)) || new Date());
      setSchoolYear(schoolYearId);
      setSubscriptionEnabled(!!subscription_enabled);
    }
  }, [record]);

  return (
    <Edit title="Modifier la session d'examen" {...props}>
      <Card className={classes.root}>
        <CardHeader title="Modifier la session d'examen" />
        <CardContent>
          {loading || loadingFetch ? (
            <Loading />
          ) : (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={4}>
                <Grid item lg={6} sm={8}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!error['data.school_year']}
                  >
                    <InputLabel id="schoolYearLabel">Année scolaire</InputLabel>
                    <Select
                      labelId="schoolYearLabel"
                      id="schoolYear"
                      value={schoolYear}
                      onChange={(event) => setSchoolYear(event.target.value)}
                      label="Année scolaire"
                    >
                      <MenuItem value="" disabled>
                        Veuillez choisir
                      </MenuItem>
                      {schoolYears.map(({ id, name }) => (
                        <MenuItem value={id}>{name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{error['data.school_year']}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Titre"
                    variant="outlined"
                    fullWidth
                    error={!!error['data.name']}
                    helperText={error['data.name']}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDateTimePicker
                    variant="dialog"
                    cancelLabel="Annuler"
                    okLabel="Valider"
                    ampm={false}
                    label="Date de début"
                    value={startDate}
                    format="DD/MM/yyyy HH:mm:ss"
                    onChange={(date) => setStartDate(date)}
                    fullWidth
                    locale="fr"
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDateTimePicker
                    variant="dialog"
                    cancelLabel="Annuler"
                    okLabel="Valider"
                    ampm={false}
                    label="Date de fin"
                    value={endDate}
                    format="DD/MM/yyyy HH:mm:ss"
                    onChange={(date) => setEndDate(date)}
                    fullWidth
                    locale="fr"
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDateTimePicker
                    variant="dialog"
                    cancelLabel="Annuler"
                    okLabel="Valider"
                    ampm={false}
                    label="Date limite de dépôt de dossier"
                    value={deadlineDate}
                    format="DD/MM/yyyy HH:mm:ss"
                    onChange={(date) => setDeadlineDate(date)}
                    fullWidth
                    locale="fr"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={subscriptionEnabled}
                        onChange={(event) =>
                          setSubscriptionEnabled(event.target.checked)
                        }
                      />
                    }
                    label="Inscription active"
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.saveButton}
          >
            Enregistrer
            {saving && (
              <Box className={classes.progress}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={16}
                />
              </Box>
            )}
          </Button>
          <Button
            startIcon={<CloseIcon />}
            variant="contained"
            color="default"
            onClick={() => redirect('/entrance_exam_sessions')}
            className={classes.cancelButton}
          >
            Annuler
          </Button>
        </CardActions>
      </Card>
    </Edit>
  );
};

export default ExamSessionEdit;
