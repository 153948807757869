import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Edit,
  required,
  TextInput,
  FormWithRedirect,
  Toolbar,
  SaveButton,
  SelectInput,
  TextField,
} from 'react-admin';
import { Api } from '../../utils/Api';

const AssignmentEdit = (props) => (
  <Edit {...props}>
    <AssignmentForm />
  </Edit>
);

const AssignmentForm = (props) => {
  const [gradeChoices, setGradeChoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.get('/list/entrance_grades').then((response) => {
      setLoading(false);
      if (response.status === 200) setGradeChoices(response.data);
    });
  }, []);

  return !loading ? (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Nom de l'épreuve
                </Typography>
                <TextInput
                  source="name"
                  validate={requiredValidate}
                  fullWidth
                  label="Nom de l'épreuve"
                />
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Contenu de l'épreuve
                </Typography>
                <TextInput
                  multiline
                  fullWidth
                  validate={requiredValidate}
                  source="content"
                  label="Contenu de l'épreuve"
                />
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Niveau rattaché à l'épreuve
                </Typography>
                <SelectInput
                  source="grade"
                  label="Niveau"
                  choices={gradeChoices.map(({ id, name }) => ({ id, name }))}
                />
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="assignments"
            >
              <SaveButton
                label="Enregistrer"
                submitOnEnter={true}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  ) : (
    <Box padding={8} textAlign="center">
      <CircularProgress />
    </Box>
  );
};

const requiredValidate = [required()];

export default AssignmentEdit;
