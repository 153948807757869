import React from 'react';
import {
  BulkExportButton,
  List,
  Datagrid,
  TextField,
  // ShowButton,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';

const OldRegisterBulkActionButtons = (props) => (
  <>
    <BulkExportButton {...props} />
  </>
);

const OldRegisterFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Nom" source="lastName" alwaysOn />
    <TextInput label="Prénom" source="firstName" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <SelectInput
      source="status"
      choices={[
        { id: 0, name: 'Par défaut' },
        { id: 1, name: 'En cours' },
        { id: 2, name: 'Validé' },
      ]}
    />
  </Filter>
);

const OldRegister = (props) => {
  return (
    <List
      {...props}
      title="Liste des réinscriptions"
      bulkActionButtons={<OldRegisterBulkActionButtons />}
      sort={{ field: 'id', order: 'ASC' }}
      filters={<OldRegisterFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField
          label="Nom"
          source="person_id"
          reference="persons"
          link={false}
          sortBy="p.lastName"
        >
          <TextField source="lastname" />
        </ReferenceField>
        <ReferenceField
          label="Prénom"
          source="person_id"
          reference="persons"
          link={false}
          sortBy="p.firstName"
        >
          <TextField source="firstname" />
        </ReferenceField>
        <ReferenceField
          label="Email"
          source="user_id"
          reference="users"
          link={false}
          sortBy="u.email"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Niveau"
          source="grade_id"
          reference="grades"
          link={false}
          sortBy="g.id"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Cursus"
          source="cursus_id"
          reference="cursus"
          link={false}
          sortBy="c.name"
        >
          <TextField source="name" />
        </ReferenceField>
        {/* <ShowButton /> */}
        {/*<EditButton />*/}
      </Datagrid>
    </List>
  );
};

export default OldRegister;
