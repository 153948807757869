import { AdminApi, Api } from '../utils/Api';

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    return Api.post('/login', { username, password }).then(
      ({ data, status }) => {
        if (status === 200) {
          const token = data['0'];
          sessionStorage.setItem('X-AUTH-TOKEN', token);
          AdminApi.defaults.headers.common['X-AUTH-TOKEN'] = token;
          Api.defaults.headers.common['X-AUTH-TOKEN'] = token;
          return Promise.resolve(token);
        }
        return Promise.reject(data);
      }
    );
  },

  // called when the user clicks on the logout button
  logout: () => {
    sessionStorage.removeItem('X-AUTH-TOKEN');
    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('X-AUTH-TOKEN');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return sessionStorage.getItem('X-AUTH-TOKEN')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
