import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ImageViewer } from '.';
import {
  Assignment,
  AssignmentInd,
  Book,
  Cake,
  Description,
  Face,
  GetApp,
  InfoOutlined,
  LocationCity,
  Payment,
  Receipt,
  School,
  Work,
  Gavel,
  PersonPinCircle,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import DocumentViewer from './DocumentViewer';
import { AdminApi } from '../utils/Api';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
  },
  header: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
}));

const getDocumentTypeName = (type) => {
  switch (type) {
    case 100:
      return 'CIN';

    case 110:
      return 'Certificat de naissance';

    case 120:
      return 'Passeport';

    case 130:
      return 'Relevé de note';

    case 140:
      return 'CV';

    case 150:
      return "Photo d'identité";

    case 160:
      return 'Certificat de travail';

    case 170:
      return 'Diplôme';

    case 180:
      return 'Lettre de motivation';

    case 190:
      return 'Bordereau de versement';

    case 200:
      return 'Certificat de résidence';

    case 210:
      return 'Certificat de résidence du tuteur/parent';

    case 220:
      return '';

    case 240:
      return 'Document épreuve';

    case 250:
      return "Fiche d'engagement";

    case 260:
      return 'Bordereau de versement - Frais de scolarité';

    case 270:
      return 'Règlements intérieurs';

    case 280:
      return 'Documents';

    default:
      return '';
  }
};

const getIcon = (type) => {
  switch (type) {
    case 100:
      return <AssignmentInd />;

    case 110:
      return <Cake />;

    case 120:
      return <AssignmentInd />;

    case 130:
      return <Receipt />;

    case 140:
      return <Description />;

    case 150:
      return <Face />;

    case 160:
      return <Work />;

    case 170:
      return <School />;

    case 180:
      return <Book />;

    case 190:
      return <Payment />;

    case 200:
      return <LocationCity />;

    case 210:
      return <PersonPinCircle />;

    case 240:
      return <Assignment />;

    case 250:
      return <Assignment />;

    case 260:
      return <Payment />;

    case 270:
      return <Gavel />;

    case 280:
      return <Payment />;

    default:
      return <InfoOutlined />;
  }
};

const DocumentField = (props) => {
  const { id, type, mime_type, showDeleteButton, onDelete } = props;
  const extension = mime_type && mime_type.split('/').pop();

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const dataURI = `${
    process.env.REACT_APP_ADMIN_URL
  }/document/${id}?token=${sessionStorage.getItem('X-AUTH-TOKEN')}`;

  const download = () => {
    const url = `${dataURI}&download=true`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', true);
    link.click();
  };

  const deleteDocument = async () => {
    await AdminApi.delete(`/document/${id}`);
    onDelete();
  };

  return (
    <>
      {
        <ListItem button onClick={() => setOpen(true)}>
          <ListItemIcon>{getIcon(type)}</ListItemIcon>
          <ListItemText primary={getDocumentTypeName(type)} />
          {showDeleteButton && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={deleteDocument}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      }
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className={classes.header} disableTypography>
          <Typography component="h2" variant="h6" className={classes.title}>
            {getDocumentTypeName(type)}
          </Typography>
          <Button variant="contained" color="primary" onClick={download}>
            <GetApp />
            Télécharger
          </Button>
        </DialogTitle>
        <DialogContent>
          {!extension ? (
            <Typography>Données corrompues</Typography>
          ) : extension === 'png' ||
            extension === 'jpg' ||
            extension === 'jpeg' ||
            extension === 'bmp' ||
            extension === 'gif' ? (
            <ImageViewer src={dataURI} className={classes.image} />
          ) : (
            <DocumentViewer path={dataURI} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DocumentField;
