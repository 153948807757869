import React, {
  cloneElement,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  List,
  Datagrid,
  TextField,
  // EditButton,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  BulkExportButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  EditButton,
  useRecordSelection,
  useSelectionState,
  // ShowButton,
} from 'react-admin';
import { AdminApi, Api } from '../../utils/Api';
import { exportToCSV } from '../../utils/export';
import * as moment from 'moment';
import { Button } from '@material-ui/core';
import IconEvent from '@material-ui/icons/Event';

let hasFilter = false;

const ApplicantBulkActionButtons = (props) => {
  return (
    <Fragment>
      <BulkExportButton {...props} />
    </Fragment>
  );
};

const ApplicantFilter = (props) => {
  const [examSessions, setExamSessions] = useState([]);
  const [grades, setGrades] = useState([]);

  const { filterValues } = useListContext();

  useLayoutEffect(() => {
    Api.get('/list/entrance-exam-session').then((response) => {
      if (response.status === 200) setExamSessions(response.data);
    });
  }, []);

  useLayoutEffect(() => {
    Api.get('/list/entrance_grades').then((response) => {
      if (response.status === 200) setGrades(response.data);
    });
  }, []);

  useEffect(() => {
    hasFilter = !!Object.keys(filterValues).length;
    console.log(hasFilter);
  }, [filterValues]);

  return (
    <Filter {...props}>
      <TextInput label="Id" source="id" alwaysOn />
      <TextInput label="Nom" source="lastName" alwaysOn />
      <TextInput label="Prénom" source="firstName" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
      <SelectInput
        source="status"
        label="Statut"
        choices={[
          { id: 0, name: 'Par défaut' },
          { id: 1, name: 'En cours' },
          { id: 2, name: 'Validé' },
          { id: 3, name: 'Renvoyé' },
          { id: 4, name: 'Reçu au concours' },
        ]}
      />
      <SelectInput
        source="step"
        label="Étape"
        choices={[
          { id: 1, name: 'Étape 1' },
          { id: 2, name: 'Étape 2' },
        ]}
      />
      <SelectInput
        source="examSession"
        label="Session"
        choices={examSessions}
      />
      <SelectInput source="grade" label="Niveau" choices={grades} />
      <BooleanInput
        defaultValue={false}
        label="Épreuve déposé"
        source="assignment"
      />
    </Filter>
  );
};

const applicantExporter = async (applicants, fetchRelatedRecords) => {
  console.log(applicants);
  if (hasFilter) {
    const persons = await fetchRelatedRecords(
      applicants,
      'person_id',
      'persons'
    );
    const users = await fetchRelatedRecords(applicants, 'user_id', 'users');

    const data = applicants.map(({ id, person_id, user_id }) => ({
      id,
      lastname: persons[person_id] && persons[person_id].lastname,
      firstname: persons[person_id] && persons[person_id].firstname,
      email: users[user_id] && users[user_id].email,
    }));

    const date = moment().locale('fr').format('Do MMM YYYY, h-mm-ss');

    exportToCSV(data, `Applicants-export-${date}`, {
      id: 'ID',
      lastname: 'Nom',
      firstname: 'Prénom',
      email: 'Email',
    });
  } else {
    // TODO: Export all informations if no filter is provided
    AdminApi.get('/applicant/info', {
      params: { ids: applicants.map((a) => a.id).join(',') },
    }).then(({ data: datas }) => {
      const date = moment().locale('fr').format('Do MMM YYYY, h-mm-ss');

      datas = datas.map((data) => {
        data['Centre d’intérêt'] = data['Centre d’intérêt'].join(', ');

        if (Array.isArray(data['Infos de filiation'])) {
          data['Infos de filiation'] = data['Infos de filiation'].map(
            (filiation) => ({
              [`Nom ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Nom'],
              [`Prénom ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Prénom'],
              [`Email ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Email primaire'],
              [`Téléphone ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Téléphone'],
              [`Société ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Société'],
              [`Adresse société ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Adresse Société'],
              [`Téléphone société ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Téléphone Société'],
              Fonction: filiation['Fonction'],
              [`Poste ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]: filiation['Poste'] && filiation['Poste'].name,
              [`Domaine d'activité ${
                filiation['Type'] === 'Mère' ? 'de la mère' : 'du père'
              }`]:
                filiation["Domaine d'activité"] &&
                filiation["Domaine d'activité"].name,
            })
          );

          let filiations = {};
          data['Infos de filiation'].forEach((filiation) => {
            filiations = {
              ...filiations,
              ...filiation,
            };
          });

          data = {
            ...data,
            ...filiations,
          };

          delete data['Infos de filiation'];
        }

        let school = data["Ecole d'origine"];
        data["Ecole d'origine"] = school["Ecole d'origine"];
        data["Lieu de l'école d'origine"] = school['Lieu'];
        delete data["Ecole d'origine"];

        return data;
      });

      console.log(datas);

      exportToCSV(datas, `Applicants-export-${date}`);
    });
  }
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const ApplicantList = (props) => {
  return (
    <List
      {...props}
      title="Liste des inscriptions"
      bulkActionButtons={<ApplicantBulkActionButtons />}
      sort={{ field: 'id', order: 'ASC' }}
      filters={<ApplicantFilter />}
      exporter={applicantExporter}
      actions={<ListActions />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField
          label="Nom"
          source="person_id"
          reference="persons"
          link={false}
          sortBy="p.lastName"
        >
          <TextField source="lastname" />
        </ReferenceField>
        <ReferenceField
          label="Prénom"
          source="person_id"
          reference="persons"
          link={false}
          sortBy="p.firstName"
        >
          <TextField source="firstname" />
        </ReferenceField>
        <ReferenceField
          label="Email"
          source="user_id"
          reference="users"
          link={false}
          sortBy="u.email"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Niveau"
          source="grade_id"
          reference="grades"
          link={false}
          sortBy="g.name"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Cursus"
          source="cursus_id"
          reference="cursus"
          link={false}
          sortBy="c.name"
        >
          <TextField source="name" />
        </ReferenceField>
        {/* <ShowButton /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ApplicantList;
