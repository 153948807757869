import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    '& .loader': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, .4)',
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

const LongPressButton = ({
  children,
  delay = 5000,
  onLongPress,
  message,
  autoHideDuration,
  className,
  ...props
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loading && progress < delay) {
      const timer = setTimeout(
        () => setProgress((progress) => progress + 100),
        100
      );

      return () => clearTimeout(timer);
    } else if (progress >= delay) {
      setLoading(false);
      setProgress(0);
      onLongPress();
    }
  }, [loading, progress, delay, onLongPress]);

  useEffect(() => {
    if (!loading) {
      setProgress(0);
    }
  }, [loading]);

  const handleMouseDown = () => {
    setLoading(true);
  };

  const cancel = () => {
    if (loading) {
      setLoading(false);
      setProgress(0);
      if (progress < delay) {
        setOpen(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Button
        {...props}
        className={clsx(classes.root, className)}
        onMouseDown={handleMouseDown}
        onMouseUp={cancel}
        onMouseLeave={cancel}
      >
        {children}
        {loading && (
          <div className="loader">
            <CircularProgress
              variant="static"
              value={(progress * 100) / delay}
              size={22}
            />
          </div>
        )}
      </Button>
      <Snackbar
        message={`Appuyez pendant ${delay / 1000} seconde${
          delay / 1000 > 1 ? 's' : ''
        } pour valider l'action`}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        autoHideDuration={autoHideDuration || 4000}
      />
    </React.Fragment>
  );
};

export default LongPressButton;
