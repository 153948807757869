import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Create, Loading, useNotify, useRedirect } from 'react-admin';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import { AdminApi } from '../../utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  saveButton: {
    position: 'relative',
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
}));

const CursusCreate = (props) => {
  const classes = useStyles();

  const redirect = useRedirect();
  const notify = useNotify();

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');

  const save = () => {
    setSaving(true);
    AdminApi.post('/cursus/new', {
      name,
    })
      .then(() => {
        redirect('/cursus');
        notify("Succès de l'opération");
      })
      .catch(() => {
        notify('Erreur...');
      });
  };

  return (
    <Create title="Créer un parcours" {...props}>
      <Card className={classes.root}>
        <CardHeader title="Créer un parcours" />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Titre"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.saveButton}
          >
            Enregistrer
            {saving && (
              <Box className={classes.progress}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={16}
                />
              </Box>
            )}
          </Button>
          <Button
            startIcon={<CloseIcon />}
            variant="contained"
            color="default"
            onClick={() => redirect('/cursus')}
            className={classes.cancelButton}
          >
            Annuler
          </Button>
        </CardActions>
      </Card>
    </Create>
  );
};

export default CursusCreate;
