import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: '#aaa',
  },
}));

const getTypeName = (type) => {
  switch (type) {
    case 20:
      return 'Père';

    case 30:
      return 'Mère';

    case 40:
      return 'Tuteur';

    default:
      return 'Type invalide';
  }
};

const ParentField = ({ record }) => {
  const {
    type,
    firstname,
    lastname,
    phone,
    primary_email: email,
    job_title: { name: job_title },
    job_details,
    company,
    company_activity: { name: company_activity },
    company_address,
    company_phone,
  } = record;

  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{getTypeName(type)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Nom
            </Typography>
            <Typography variant="body2">{lastname}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Prénom
            </Typography>
            <Typography variant="body2">{firstname}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Email
            </Typography>
            <Typography variant="body2">{email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Numéro téléphone
            </Typography>
            <Typography variant="body2">{phone}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Emploi
            </Typography>
            <Typography variant="body2">{job_title}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.title}>
              Détails de l'emploi
            </Typography>
            <Typography variant="body2">{job_details}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Entreprise
            </Typography>
            <Typography variant="body2">{company}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Secteur d'activité
            </Typography>
            <Typography variant="body2">{company_activity}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>
              Adresse de l'entreprise
            </Typography>
            <Typography variant="body2">{company_address}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Téléphone de l'entreprise
            </Typography>
            <Typography variant="body2">{company_phone}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ParentField;
