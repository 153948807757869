import React, { useEffect, useState } from 'react';
import {
  TopToolbar,
  ListButton,
  EditButton,
  Show,
  ReferenceField,
  TextField,
  useNotify,
} from 'react-admin';
import { Box, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DocumentList,
  ChangeState,
  ShowLayout,
  ParentList,
} from '../../components';
import { AdminApi, Api } from '../../utils/Api';

const ApplicantShowActions = (props) => {
  const { basePath, data: record } = props;

  return (
    <TopToolbar>
      {record && <ChangeState record={record} />}
      <Box width={20} />
      <ListButton basePath={basePath} />
      <EditButton basePath={basePath} record={record} />
    </TopToolbar>
  );
};

const useApplicantTitleStyles = makeStyles(() => ({
  name: {
    fontWeight: 'bold',
  },
}));

const ApplicantTitle = ({ record, basePath }) => {
  const classes = useApplicantTitleStyles();

  return (
    <>
      <ReferenceField
        record={record}
        basePath={basePath}
        source="person_id"
        reference="persons"
        link={false}
      >
        <TextField source="lastname" className={classes.name} />
      </ReferenceField>{' '}
      <ReferenceField
        record={record}
        basePath={basePath}
        source="person_id"
        reference="persons"
        link={false}
      >
        <TextField source="firstname" className={classes.name} />
      </ReferenceField>
    </>
  );
};

const SelectExamSession = ({ record }) => {
  const notify = useNotify();
  const [value, setValue] = useState(record.entrance_exam_session.id);
  const [examSessions, setExamSessions] = useState([]);

  useEffect(() => {
    Api.get('/list/entrance-exam-session').then((response) => {
      if (response.status === 200) setExamSessions(response.data);
    });
  }, [record]);

  const handleChange = (event) => {
    const { value } = event.target;
    AdminApi.put(`/applicant/${record.id}/exam-session/update`, {
      'exam-session': value,
    })
      .then((response) => {
        if (response.status === 200) {
          setValue(value);
          notify('Councours changé avec succès');
        }
      })
      .catch((reason) => {
        notify("Échec de l'opération");
      });
  };

  return (
    <Select
      labelId="entrance-exam-session"
      id="entrance-exam-session"
      value={value}
      onChange={handleChange}
    >
      {examSessions.map(({ id, name }) => (
        <MenuItem value={id}>{name}</MenuItem>
      ))}
    </Select>
  );
};

export const ApplicantShow = (props) => {
  const { basePath, record } = props;
  console.log(props);

  return (
    <>
      <Show
        {...props}
        actions={<ApplicantShowActions />}
        title={<ApplicantTitle basePath={basePath} record={record} />}
      >
        <ShowLayout>
          <ReferenceField
            label="Cursus"
            source="cursus_id"
            reference="cursus"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Niveau"
            source="grade_id"
            reference="grades"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <SelectExamSession
            label="Concours"
            source="entrance_exam_session.name"
          />
          <TextField label="Étape" source="step" />
          <ReferenceField
            label="Nom"
            source="person_id"
            reference="persons"
            link={false}
          >
            <TextField source="lastname" />
          </ReferenceField>

          <ReferenceField
            label="Prénom"
            source="person_id"
            reference="persons"
            link={false}
          >
            <TextField source="firstname" />
          </ReferenceField>
          <ReferenceField
            label="Email"
            source="user_id"
            reference="users"
            link={false}
          >
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Nom d'usage"
            source="person_id"
            reference="persons"
            link={false}
          >
            <TextField source="usage_name" />
          </ReferenceField>
          <ReferenceField
            label="Numéro de téléphone"
            source="person_id"
            reference="persons"
            link={false}
          >
            <TextField source="mobile_phone" />
          </ReferenceField>
          <ReferenceField
            label="Adresse"
            source="person_id"
            reference="persons"
            link={false}
          >
            <TextField source="address" />
          </ReferenceField>
          <ParentList />
          <DocumentList label="Documents" />
        </ShowLayout>
      </Show>
      <Show {...props}>
        <div style={{ padding: 30 }}>
          <h3>Autre informations</h3>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: 50,
              alignItems: 'center',
            }}
          >
            <h5>Comment avez-vous connu l'ISCAM</h5>
            <ReferenceField
              label=""
              source="id"
              reference="applicants"
              link={false}
            >
              <TextField source="how_did_u_know_us" />
            </ReferenceField>
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: 50,
              alignItems: 'center',
            }}
          >
            <h5>Besoin d'hebergement</h5>
            <ReferenceField
              label="Besoin d'hébergement"
              source="id"
              reference="applicants"
              link={false}
            >
              <TextField source="need_rent" />
            </ReferenceField>
          </div>
        </div>
      </Show>
    </>
  );
};

export default ApplicantShow;
