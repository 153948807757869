import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    backgroundColor: 'rgba(0, 0, 0, .6)',
  },
  imagePreview: {
    position: 'absolute',
    overflow: 'auto',
  },
  zoomInCursor: {
    cursor: 'zoom-in',
  },
  zoomOutCursor: {
    cursor: 'zoom-out',
  },
}));

const ImageViewer = ({ src, alt, className }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [dragging] = useState(false);
  const [pos, setPos] = useState({ left: 0, top: 0, x: 0, y: 0 });
  const [mousePressed, setMousePressed] = useState(false);
  const [{ x, y, width, height }, setInitialRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const openViewer = (event) => {
    const { x, y, height } = event.target.getClientRects()[0];
    setInitialRect({ x, y, height });
    setOpen(true);
  };

  const handleImagePreviewClick = () => {
    if (zoom === 1) setZoom(2);
    else setZoom(1);
  };

  const mouseDownHandler = (event) => {
    setMousePressed(true);

    const ele = event.target;
    setPos({
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: event.clientX,
      y: event.clientY,
    });
  };

  const mouseUpHandler = () => {
    setMousePressed(false);
  };

  const mouseMoveHandler = (event) => {
    // Scroll the element
    if (mousePressed) {
      const ele = event.target;

      const dx = event.clientX - pos.x;
      const dy = event.clientY - pos.y;

      ele.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    }
  };

  useEffect(() => {
    if (open) {
      document.body.onscroll = () => {
        if (open && zoom === 1) setOpen(false);
      };
      document.onmousemove = mouseMoveHandler;
      document.onmouseup = mouseUpHandler;
    } else {
      document.body.onscroll = null;
      document.onmousemove = null;
      document.onmouseup = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, zoom]);

  return (
    <React.Fragment>
      <img
        src={src}
        alt={alt}
        className={clsx(className)}
        onClick={openViewer}
      />

      <AnimatePresence>
        {open && (
          <motion.div
            id="overlay"
            className={classes.overlay}
            transition={{
              duration: 0.6,
            }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            onClick={(event) => {
              event.persist();
              if (event.target.id === 'overlay') {
                const {
                  x,
                  y,
                  width,
                  height,
                } = event.target.parentElement
                  .querySelector('img')
                  .getClientRects()[0];
                setInitialRect({ x, y, width, height });
                setOpen(false);
              }
            }}
          >
            <motion.div
              transition={{
                duration: 0.6,
              }}
              initial={{
                width,
                height,
                top: y,
                left: x,
                transform: 'scale(1) translate(0, 0)',
              }}
              animate={{
                width: `${
                  (width * document.body.offsetWidth) /
                  (document.body.offsetHeight * 0.8)
                }px`,
                height: '80vh',
                top: '50%',
                left: '50%',
                transform: 'scale(1) translate(-50%, -50%)',
              }}
              exit={{
                height: '80vh',
                top: '50%',
                left: '50%',
                transform: 'scale(0) translate(0, 0)',
              }}
              className={clsx(classes.imagePreview, {
                [classes.zoomInCursor]: zoom === 1,
                [classes.zoomOutCursor]: zoom === 2,
                [classes.dragging]: dragging,
              })}
              onClick={handleImagePreviewClick}
              onMouseDown={mouseDownHandler}
              onMouseUp={mouseUpHandler}
              onMouseMove={mouseMoveHandler}
            >
              <motion.img
                style={{
                  transformOrigin: 'top left',
                }}
                initial={{
                  scale: 1,
                }}
                animate={{
                  scale: zoom,
                }}
                src={src}
                alt={alt}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

export default ImageViewer;
