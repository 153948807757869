import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {
  Loading,
  Edit,
  useEditController,
  useRedirect,
  useRefresh,
  useNotify,
} from 'react-admin';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { AdminApi, Api } from '../../utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  saveButton: {
    position: 'relative',
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: '#aaa',
  },
}));

const GradeEdit = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const [saving, setSaving] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [cursus, setCursus] = useState([]);
  const [gradeId, setId] = useState();
  const [name, setName] = useState('');
  const [cursusOptions, setCursusOptions] = useState([]);

  const { loading, record } = useEditController(props);

  const save = () => {
    setSaving(true);
    AdminApi.put(`/grade/${gradeId}`, {
      name,
    })
      .then(() => {
        setSaving(false);
        redirect('/grades');
        notify('Succès');
      })
      .catch(() => {
        setSaving(false);
        notify('Erreur...');
      });
  };

  useEffect(() => {
    setLoadingFetch(true);
  }, []);

  useEffect(() => {
    if (record) {
      setId(record.id);
      setName(record.name);

      Api.get('/list/cursuses').then(({ data }) => {
        setCursusOptions(data[0]);
        setLoadingFetch(false);
        setCursus(data[record.id] || []);
      });
    }
  }, [record]);

  return (
    <Edit title="Modifier les détails du niveau" {...props}>
      <Card className={classes.root}>
        <CardHeader title="Modifier les détails du niveau" />
        <CardContent>
          {loading || loadingFetch ? (
            <Loading />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Titre"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Liste des cursus rattachés
                </Typography>
              </Grid>
              {!cursus.length && (
                <Grid item xs={12}>
                  <Typography variant="body1">Aucun cursus rattaché</Typography>
                </Grid>
              )}
              {cursus.map(({ id, name }, index) => (
                <Grid item key={index} container spacing={2}>
                  <Grid
                    item
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: 30, display: 'flex' }}
                  >
                    <Typography>{index + 1}</Typography>
                  </Grid>
                  <Grid item xs={10} md={5}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id={`cursus-${index}-label`}>
                        Cursus
                      </InputLabel>
                      <Select
                        labelId={`cursus-${index}-label`}
                        id={`cursus-${index}`}
                        value={id}
                        label="Cursus"
                        onChange={(event) => {
                          event.persist();
                          if (id != '')
                            AdminApi.put(`/grade/${gradeId}/removeCursus`, {
                              cursus_id: id,
                            });
                          AdminApi.put(`/grade/${gradeId}/addCursus`, {
                            cursus_id: Number(event.target.value),
                          });
                          const newCursus = cursusOptions.filter(
                            (e) => e.id === Number(event.target.value)
                          )[0];
                          console.log(
                            cursusOptions,
                            newCursus,
                            event.target.value
                          );
                          setCursus([
                            ...cursus.slice(0, index),
                            newCursus,
                            ...cursus.slice(index + 1),
                          ]);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Veuillez choisir
                        </MenuItem>
                        <MenuItem value={id}>{name}</MenuItem>
                        {cursusOptions
                          .filter(
                            (e) => !cursus.filter((i) => i.id === e.id).length
                          )
                          .map(({ id, name }) => (
                            <MenuItem value={id}>{name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs
                    jusifyContent="center"
                    alignItems="center"
                    style={{ display: 'flex' }}
                  >
                    <Button
                      variant="contained"
                      style={{ backgroundColor: red[600], color: 'white' }}
                      onClick={() => {
                        if (id === '')
                          return setCursus([
                            ...cursus.slice(0, index),
                            ...cursus.slice(index + 1),
                          ]);

                        AdminApi.put(`/grade/${gradeId}/removeCursus`, {
                          cursus_id: id,
                        }).then(() => {
                          Api.get('/list/cursuses').then(({ data }) => {
                            setCursusOptions(data[0]);
                            setLoadingFetch(false);
                            setCursus(data[record.id]);
                          });
                        });
                      }}
                    >
                      Supprimer
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid item container>
                <Button
                  variant="contained"
                  onClick={() => {
                    setCursus([...cursus, { id: '' }]);
                  }}
                >
                  <AddIcon style={{ marginRight: '.5em' }} />
                  Ajouter un cursus
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.saveButton}
          >
            Enregistrer
            {saving && (
              <Box className={classes.progress}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={16}
                />
              </Box>
            )}
          </Button>
          <Button
            startIcon={<CloseIcon />}
            variant="contained"
            color="default"
            onClick={() => redirect('/grades')}
            className={classes.cancelButton}
          >
            Annuler
          </Button>
        </CardActions>
      </Card>
    </Edit>
  );
};

export default GradeEdit;
