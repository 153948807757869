import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

const ExamSessionActions = (props) => {
  const { className, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} label="Ajouter une session d'examen" />
    </TopToolbar>
  );
};

const ExamSessionList = (props) => (
  <List
    {...props}
    title="Liste des sessions d'examen"
    sort={{ field: 'id', order: 'ASC' }}
    actions={<ExamSessionActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" label="Titre" />
      <TextField source="start" label="Début" />
      <TextField source="end" label="Fin" />
      <TextField source="deadline" label="Date limite de dépôt de dossier" />
      <TextField source="school_year.name" label="Année scolaire" />
      <BooleanField source="subscription_enabled" label="Inscription active" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ExamSessionList;
