import axios from 'axios';
import _ from 'lodash';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: _.has(sessionStorage, 'X-AUTH-TOKEN')
    ? { 'X-AUTH-TOKEN': sessionStorage.getItem('X-AUTH-TOKEN') }
    : {},
});

export const AdminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  headers: _.has(sessionStorage, 'X-AUTH-TOKEN')
    ? { 'X-AUTH-TOKEN': sessionStorage.getItem('X-AUTH-TOKEN') }
    : {},
});
