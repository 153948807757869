/**
 * Get date instance from string
 * @param {string} dateString
 * @returns
 */
export const getDateFromString = (dateString) => {
  const parts = dateString.split(' ');

  const dates = parts[0].split('/'),
    date = Number(dates[0]),
    month = Number(dates[1]),
    year = Number(dates[2]);

  if (parts.length === 2) {
    const times = parts[1].split(':'),
      hours = times ? Number(times[0]) : undefined,
      minutes = times ? Number(times[1]) : undefined,
      seconds = times ? Number(times[2]) : undefined;

    return new Date(year, month - 1, date, hours, minutes, seconds);
  }

  return new Date(year, month - 1, date);
};
