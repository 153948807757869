import React, { useEffect, useState } from 'react';
import { LinearProgress, List, Typography } from '@material-ui/core';
import DocumentField from './DocumentField';
import { AdminApi } from '../utils/Api';

const DocumentList = ({ record: { id }, showDeleteButton }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AdminApi.get(`/applicants/${id}/documents`)
      .then((result) => {
        if (result.status === 200) {
          setData(result.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return isLoading ? (
    <LinearProgress variant="indeterminate" />
  ) : data.length ? (
    <List component="ul">
      {data.map((doc, index) => (
        <DocumentField
          onDelete={() =>
            setData([...data.slice(0, index), ...data.slice(index + 1)])
          }
          showDeleteButton={showDeleteButton}
          key={index}
          {...doc}
        />
      ))}
    </List>
  ) : (
    <Typography>Aucun document fourni</Typography>
  );
};

export default DocumentList;
