import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
  AccountBalance,
  Assessment,
  Assignment,
  ClearAll,
  Dashboard,
  HowToReg,
  SupervisorAccount,
} from '@material-ui/icons';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import {
  ApplicantList,
  ApplicantShow,
  ApplicantEdit,
  ApplicantCreate,
} from './pages/applicants';

import theme from './theme';
import {
  OldRegisterEdit,
  OldRegisterList,
  OldRegisterShow,
} from './pages/oldRegister';
import {
  AssignmentCreate,
  AssignmentEdit,
  AssignmentList,
} from './pages/assignments';
import {
  ExamSessionCreate,
  ExamSessionEdit,
  ExamSessionList,
} from './pages/examSessions';
import { GradeCreate, GradeEdit, GradeList } from './pages/grades';
import StatisticPage from './pages/statistics';
import { CursusList, CursusCreate, CursusEdit } from './pages/cursus';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const App = () => (
  <Admin
    theme={theme}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      icon={Dashboard}
      name="statistics"
      options={{ label: 'Statistiques' }}
      list={StatisticPage}
    />
    <Resource
      name="applicants"
      icon={HowToReg}
      options={{ label: 'Inscriptions' }}
      list={ApplicantList}
      show={ApplicantShow}
      edit={ApplicantEdit}
      create={ApplicantCreate}
    />
    <Resource
      name="oldregisters"
      icon={SupervisorAccount}
      options={{ label: 'Réinscriptions' }}
      list={OldRegisterList}
      show={OldRegisterShow}
      edit={OldRegisterEdit}
    />
    <Resource
      name="assignments"
      icon={Assignment}
      options={{ label: 'Épreuve' }}
      create={AssignmentCreate}
      list={AssignmentList}
      edit={AssignmentEdit}
    />
    <Resource
      name="entrance_exam_sessions"
      icon={Assessment}
      options={{ label: "Sessions d'examen" }}
      list={ExamSessionList}
      create={ExamSessionCreate}
      edit={ExamSessionEdit}
    />
    <Resource
      name="grades"
      icon={ClearAll}
      options={{ label: 'Niveaux' }}
      list={GradeList}
      create={GradeCreate}
      edit={GradeEdit}
    />
    <Resource
      name="cursus"
      icon={AccountBalance}
      options={{ label: 'Parcours' }}
      list={CursusList}
      create={CursusCreate}
      edit={CursusEdit}
    />
    <Resource name="persons" />
    <Resource name="users" />
    <Resource name="applications" />
  </Admin>
);

export default App;
