import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: '#aaa',
  },
}));

const ShowLayout = (props) => {
  const { record, basePath, children } = props;

  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            {children[0].props.label || 'Aucun label'}
          </Typography>
          {children[0].props.source && record[children[0].props.source] ? (
            React.cloneElement(children[0], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            {children[1].props.label || 'Aucun label'}
          </Typography>
          {children[1].props.source && record[children[1].props.source] ? (
            React.cloneElement(children[1], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          {children[2].props.source && (
            <Typography variant="h6" className={classes.title}>
              {children[2].props.label || 'Aucun label'}
            </Typography>
          )}
          {children[2].props.source
            ? React.cloneElement(children[2], { basePath, record })
            : children[2].props.source && (
                <Typography>Aucune donnée</Typography>
              )}
        </Grid>
        <Grid item xs={3}>
          {children[3].props.source && (
            <Typography variant="h6" className={classes.title}>
              {children[3].props.label || 'Aucun label'}
            </Typography>
          )}
          {children[3].props.source
            ? React.cloneElement(children[3], { basePath, record })
            : children[3].props.source && (
                <Typography>Aucune donnée</Typography>
              )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom={false}
            className={classes.heading}
          >
            Détails du candidat
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            {children[4].props.label || 'Aucun label'}
          </Typography>
          {children[4].props.source && record[children[4].props.source] ? (
            React.cloneElement(children[4], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            {children[5].props.label || 'Aucun label'}
          </Typography>
          {children[5].props.source && record[children[5].props.source] ? (
            React.cloneElement(children[5], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.title}>
            {children[6].props.label || 'Aucun label'}
          </Typography>
          {children[6].props.source && record[children[6].props.source] ? (
            React.cloneElement(children[6], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.title}>
            {children[7].props.label || 'Aucun label'}
          </Typography>
          {children[7].props.source && record[children[7].props.source] ? (
            React.cloneElement(children[7], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            {children[8].props.label || 'Aucun label'}
          </Typography>
          {children[8].props.source && record[children[8].props.source] ? (
            React.cloneElement(children[8], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" className={classes.title}>
            {children[9].props.label || 'Aucun label'}
          </Typography>
          {children[9].props.source && record[children[9].props.source] ? (
            React.cloneElement(children[9], { basePath, record })
          ) : (
            <Typography>Aucune donnée</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom={false}
            className={classes.heading}
          >
            Détails des parents du candidat
          </Typography>
        </Grid>
        {React.cloneElement(children[10], { basePath, record })}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {children[11].props.label || 'Aucun label'}
          </Typography>
          {React.cloneElement(children[11], { basePath, record })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShowLayout;
