import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
} from 'react-admin';

const AssignmentList = (props) => (
  <List
    {...props}
    title="Liste des épreuves"
    sort={{ field: 'id', order: 'ASC' }}
  >
    <Datagrid rowClick="toggleSelection">
      <TextField source="id" />
      <ReferenceField
        label="Niveau"
        source="grade"
        reference="grades"
        link={false}
        sortBy="g.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Nom de l'épreuve" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AssignmentList;
