import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Create,
  DateField,
  Loading,
  useNotify,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import { Add as AddIcon } from '@material-ui/icons';
import { Delete as DeleteIcon } from '@material-ui/icons';
import * as moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/moment';
import { useEffect } from 'react';
import { AdminApi, Api } from '../../utils/Api';
import { getDateFromString } from '../../utils/date';
import {
  DatePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  saveButton: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ApplicantCreate = (props) => {
  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [usage_name, setCallingName] = useState('');
  const [mobile_phone, setNumber] = useState('');
  const [birthdate, setBirthday] = useState(getDateFromString('01/01/1990'));
  const [birth_city, setBirthplace] = useState('');
  const [birth_country, setBirthcountry] = useState('');
  const [nationality, setNationality] = useState('');
  const [marital_status, setMaritalStatus] = useState('');
  const [family_member_at_iscam, setFamilyMemberAtIscam] = useState('');
  const [interests, setInterests] = useState([]);
  const [interest, setInterest] = useState('');
  const [countries, setCountries] = useState([]);
  const [address, setAdress] = useState('');
  const [knowUs, setKnowUs] = useState('');
  const [needRent, setNeedRent] = useState('');
  const [sex, setSex] = useState('');
  const [cursus, setCursus] = useState('');
  const [cursuses, setCursuses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState('');
  const [school_year, setSchoolYear] = useState('');
  const [examSession, setExamSession] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [parentList, setParentList] = useState([]);
  const [companyActivityList, setCompanyActivityList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [examSessionOptions, setExamSessionOptions] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);

  useEffect(() => {
    Api.get('/list/entrance_grades').then((response) => {
      if (response.status === 200) setGrades(response.data);
      setLoading(false);
    });

    Api.get('/list/cursuses').then((response) => {
      if (response.status === 200) setCursuses(response.data);
      setLoading(false);
    });

    Api.get('/list/countries').then((response) => {
      if (response.status === 200) setCountries(response.data);
      setLoading(false);
    });

    Api.get('/list/interests').then((response) => {
      if (response.status === 200) setInterests(response.data);
      setLoading(false);
    });

    Api.get('/list/company_activities').then((response) => {
      if (response.status === 200) setCompanyActivityList(response.data);
      setLoading(false);
    });

    Api.get('/list/job_titles').then((response) => {
      if (response.status === 200) setJobTitleList(response.data);
      setLoading(false);
    });

    Api.get('/list/job_types').then((response) => {
      if (response.status === 200) setJobTypeList(response.data);
      setLoading(false);
    });

    Api.get('/list/entrance-exam-session').then((response) => {
      if (response.status === 200) setExamSessionOptions(response.data);
      setLoading(false);
    });

    Api.get('/list/school_years').then((response) => {
      if (response.status === 200) setSchoolYears(response.data);
      setLoading(false);
    });
  }, []);

  const save = async () => {
    setSaving(true);

    try {
      const result = await AdminApi.post('/applicant', {
        knowUs,
        needRent,
        step1: {
          person: {
            last_name,
            first_name,
            usage_name,
            birthdate: moment(birthdate).format('DD/MM/yyyy'),
            sex,
            birth_city,
            birth_country,
            nationality,
            marital_status,
            address,
            secondary_address: '',
            mobile_phone,
            phone: '',
            email,
          },
          family_members_at_iscam: family_member_at_iscam,
          interests: [interest],
        },
        step2: parentList,
        step3: {
          grade,
          cursuses: cursus,
          schoolYears: school_year,
          examSession: examSession,
        },
      });
      setSaving(false);
      setError({});
      redirect('/applicants');
      notify(result.data.message);
    } catch (error) {
      setSaving(false);
      setError(error.response.data);
      notify(error.response.data.message);
    }
  };

  const addParent = () => {
    setParentList([
      ...parentList,
      {
        type: '',
        last_name: '',
        first_name: '',
        usage_name: '',
        phone: '',
        primary_email: '',
        company: '',
        company_activity: '',
        company_phone: '',
        company_address: '',
        job_details: '',
        job_type: '',
        job_title: '',
      },
    ]);
  };

  return (
    <Create {...props} title="Créer un candidat">
      <Card className={classes.root}>
        <CardContent>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom={false}
                  className={classes.heading}
                >
                  Détails du candidat
                </Typography>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Nom*"
                      variant="outlined"
                      fullWidth
                      value={last_name}
                      onChange={(event) => setLastName(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].last_name']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].last_name']
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Prénom(s)*"
                      variant="outlined"
                      fullWidth
                      value={first_name}
                      onChange={(event) => setFirstName(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].first_name']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].first_name']
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Nom d'usage*"
                      variant="outlined"
                      fullWidth
                      value={usage_name}
                      onChange={(event) => setCallingName(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].usage_name']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].usage_name']
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {/* <TextField
                      label="Date de naissance*"
                      variant="outlined"
                      fullWidth
                      value={birthdate}
                      onChange={(event) => setBirthday(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birthdate']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birthdate']
                      }
                    /> */}
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      cancelLabel="Annuler"
                      okLabel="Valider"
                      label="Date de naissance"
                      value={birthdate}
                      format="DD/MM/yyyy"
                      onChange={(date) => setBirthday(date)}
                      fullWidth
                      locale="fr"
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birthdate']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birthdate']
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Lieu de naissance*"
                      variant="outlined"
                      fullWidth
                      value={birth_city}
                      onChange={(event) => setBirthplace(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birth_city']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].birth_city']
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].birth_country'
                        ]
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].birth_country'
                        ]
                      }
                    >
                      <InputLabel id="birthcountryLabel">
                        Pays de naissance*
                      </InputLabel>
                      <Select
                        labelId="birth_country"
                        id="birth_country"
                        value={birth_country}
                        onChange={(e) => setBirthcountry(e.target.value)}
                        label="Pays de naissance"
                      >
                        {countries.map(({ id, name_fr }) => (
                          <MenuItem key={id} value={id}>
                            {name_fr}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].nationality']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].nationality']
                      }
                    >
                      <InputLabel id="nationalityLabel">
                        Nationalité*
                      </InputLabel>
                      <Select
                        labelId="nationalityLabel"
                        id="nationality"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                        label="Nationalité"
                      >
                        {countries.map(({ id, name_fr }) => (
                          <MenuItem key={id} value={id}>
                            {name_fr}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Comment connaissez-vous ISCAM*"
                      variant="outlined"
                      fullWidth
                      value={knowUs}
                      onChange={(event) => setKnowUs(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="needRentLabel">
                        Besoin d'hebergment*
                      </InputLabel>
                      <Select
                        labelId="needRentLabel"
                        id="needRent"
                        value={needRent}
                        onChange={(e) => setNeedRent(e.target.value)}
                        label="NeedRent"
                      >
                        <MenuItem value="1">Oui</MenuItem>
                        <MenuItem value="0">Non</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].sex']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].sex']
                      }
                    >
                      <InputLabel id="sexLabel">Genre*</InputLabel>
                      <Select
                        labelId="sexLabel"
                        id="sex"
                        value={sex}
                        onChange={(e) => setSex(e.target.value)}
                        label="Genre"
                      >
                        <MenuItem value="1">Homme</MenuItem>
                        <MenuItem value="0">Femme</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].marital_status'
                        ]
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].marital_status'
                        ]
                      }
                    >
                      <InputLabel id="maritalStatusLabel">
                        Situation de famille*
                      </InputLabel>
                      <Select
                        labelId="marital_status"
                        id="marital_status"
                        value={marital_status}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                        label="Situation de famille"
                      >
                        <MenuItem value="10">Célibataire</MenuItem>
                        <MenuItem value="20">Marié</MenuItem>
                        <MenuItem value="30">Divorcé</MenuItem>
                        <MenuItem value="40">Veuf</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Numéro*"
                      variant="outlined"
                      fullWidth
                      value={mobile_phone}
                      onChange={(event) => setNumber(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].mobile_phone'
                        ]
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1'][
                          'data.relationships[0].mobile_phone'
                        ]
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email*"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].email']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].email']
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Adresse*"
                      variant="outlined"
                      fullWidth
                      value={address}
                      onChange={(event) => setAdress(event.target.value)}
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].address']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.relationships[0].address']
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Nombre de membres de la famille à l'ISCAM*"
                      variant="outlined"
                      fullWidth
                      value={family_member_at_iscam}
                      onChange={(event) =>
                        setFamilyMemberAtIscam(event.target.value)
                      }
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.family_members_at_iscam']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['data.family_members_at_iscam']
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['children[interests]']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step1'] &&
                        error['0']['step1']['children[interests]']
                      }
                    >
                      <InputLabel id="interest">
                        Centre d'intérêt principal*
                      </InputLabel>
                      <Select
                        labelId="interestLabel"
                        id="interest"
                        value={interest}
                        onChange={(e) => setInterest(e.target.value)}
                        label="Centre d'intérêt principal*"
                      >
                        {interests.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {error['0'] &&
                          error['0']['step1'] &&
                          error['0']['step1']['children[interests]']}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom={false}
                      className={classes.heading}
                    >
                      Parents
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="secondary"
                      onClick={addParent}
                    >
                      Ajouter un parent
                    </Button>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  {parentList.map((parent, index) => {
                    const {
                      type,
                      last_name,
                      first_name,
                      usage_name,
                      phone,
                      primary_email,
                      company,
                      company_activity,
                      company_phone,
                      company_address,
                      job_details,
                      job_type,
                      job_title,
                    } = parent;

                    return (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item xs>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                error={
                                  error['0'] &&
                                  error['0']['step2'] &&
                                  error['0']['step2'][index] &&
                                  error['0']['step2'][index]['data.type']
                                }
                                helperText={
                                  error['0'] &&
                                  error['0']['step2'] &&
                                  error['0']['step2'][index] &&
                                  error['0']['step2'][index]['data.type']
                                }
                              >
                                <InputLabel id="type">Relation*</InputLabel>
                                <Select
                                  labelId="typeLabel"
                                  id="type"
                                  value={type}
                                  onChange={(e) => {
                                    parentList[index] = {
                                      ...parent,
                                      type: e.target.value,
                                    };
                                    setParentList([...parentList]);
                                  }}
                                  label="Relation*"
                                >
                                  <MenuItem value="20">Père</MenuItem>
                                  <MenuItem value="30">Mère</MenuItem>
                                  <MenuItem value="40">Tuteur</MenuItem>
                                </Select>
                                <FormHelperText>
                                  {error['0'] &&
                                    error['0']['step2'] &&
                                    error['0']['step2'][index] &&
                                    error['0']['step2'][index]['data.type']}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <Button
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                color="default"
                                size="large"
                                onClick={() =>
                                  setParentList([
                                    ...parentList.slice(0, index),
                                    ...parentList.slice(index + 1),
                                  ])
                                }
                              >
                                Annuler
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Nom*"
                              variant="outlined"
                              fullWidth
                              value={last_name}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  last_name: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.last_name']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.last_name']
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Prénom(s)*"
                              variant="outlined"
                              fullWidth
                              value={first_name}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  first_name: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.first_name']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.first_name']
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Prénom d'usage*"
                              variant="outlined"
                              fullWidth
                              value={usage_name}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  usage_name: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.usage_name']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.usage_name']
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Téléphone*"
                              variant="outlined"
                              fullWidth
                              value={phone}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  phone: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.phone']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.phone']
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="E-mail*"
                              variant="outlined"
                              fullWidth
                              value={primary_email}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  primary_email: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.primary_email']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.primary_email']
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_title']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_title']
                              }
                            >
                              <InputLabel id="type">
                                Situation professionnelle*
                              </InputLabel>
                              <Select
                                labelId="job_title"
                                id="job_title"
                                value={job_title}
                                onChange={(e) => {
                                  const { value } = e.target;

                                  parentList[index] = {
                                    ...parent,
                                    job_title: value,
                                    company:
                                      Number(value) === 7 ||
                                      Number(value) === 8 ||
                                      Number(value) === 9
                                        ? ''
                                        : company,
                                    job_type:
                                      Number(value) === 7 ||
                                      Number(value) === 8 ||
                                      Number(value) === 9
                                        ? ''
                                        : job_type,
                                    job_details:
                                      Number(value) === 7 ||
                                      Number(value) === 8 ||
                                      Number(value) === 9
                                        ? ''
                                        : job_details,
                                    company_activity:
                                      Number(value) === 7 ||
                                      Number(value) === 8 ||
                                      Number(value) === 9
                                        ? ''
                                        : company_activity,
                                  };

                                  setParentList([...parentList]);
                                }}
                                label="Situation professionnelle*"
                              >
                                {jobTitleList.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_type']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_type']
                              }
                            >
                              <InputLabel id="type">
                                Poste dans l'organisation*
                              </InputLabel>
                              <Select
                                disabled={
                                  Number(job_title) === 7 ||
                                  Number(job_title) === 8 ||
                                  Number(job_title) === 9
                                }
                                labelId="jobTypeLabel"
                                id="jobType"
                                value={job_type}
                                onChange={(e) => {
                                  parentList[index] = {
                                    ...parent,
                                    job_type: e.target.value,
                                  };
                                  setParentList([...parentList]);
                                }}
                                label="Poste dans l'organisation*"
                              >
                                {(Number(job_title) === 7 ||
                                  Number(job_title) === 8 ||
                                  Number(job_title) === 9) && (
                                  <MenuItem value="void">Néant</MenuItem>
                                )}
                                {jobTypeList.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={
                                Number(job_title) === 7 ||
                                Number(job_title) === 8 ||
                                Number(job_title) === 9
                              }
                              label="Fonction dans l'organisation*"
                              variant="outlined"
                              fullWidth
                              value={job_details}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  job_details: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_details']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.job_details']
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled={
                                Number(job_title) === 7 ||
                                Number(job_title) === 8 ||
                                Number(job_title) === 9
                              }
                              label="Société*"
                              variant="outlined"
                              fullWidth
                              value={company}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  company: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.company']
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.company']
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              disabled={
                                Number(job_title) === 7 ||
                                Number(job_title) === 8 ||
                                Number(job_title) === 9
                              }
                              variant="outlined"
                              fullWidth
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index][
                                  'data.company_activity'
                                ]
                              }
                              helperText={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index][
                                  'data.company_activity'
                                ]
                              }
                            >
                              <InputLabel id="type">
                                Domaine d'activité*
                              </InputLabel>
                              <Select
                                labelId="companyActivityLabel"
                                id="companyActivity"
                                value={company_activity}
                                onChange={(e) => {
                                  parentList[index] = {
                                    ...parent,
                                    company_activity: e.target.value,
                                  };
                                  setParentList([...parentList]);
                                }}
                                label="Domaine d'activité*"
                              >
                                {(Number(job_title) === 7 ||
                                  Number(job_title) === 8 ||
                                  Number(job_title) === 9) && (
                                  <MenuItem value="void">Néant</MenuItem>
                                )}
                                {companyActivityList.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Téléphone société*"
                              variant="outlined"
                              fullWidth
                              value={company_phone}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  company_phone: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index]['data.company_phone']
                              }
                              helperText={
                                ((Number(job_title) === 7 ||
                                  Number(job_title) === 8 ||
                                  Number(job_title) === 9) && (
                                  <i>
                                    Merci de mettre le numéro personnel du
                                    parent s’il est Sans emploi ou Retraité
                                  </i>
                                )) ||
                                (error['0'] &&
                                  error['0']['step2'] &&
                                  error['0']['step2'][index] &&
                                  error['0']['step2'][index][
                                    'data.company_phone'
                                  ])
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Adresse société*"
                              variant="outlined"
                              fullWidth
                              value={company_address}
                              onChange={(e) => {
                                parentList[index] = {
                                  ...parent,
                                  company_address: e.target.value,
                                };
                                setParentList([...parentList]);
                              }}
                              error={
                                error['0'] &&
                                error['0']['step2'] &&
                                error['0']['step2'][index] &&
                                error['0']['step2'][index][
                                  'data.company_address'
                                ]
                              }
                              helperText={
                                ((Number(job_title) === 7 ||
                                  Number(job_title) === 8 ||
                                  Number(job_title) === 9) && (
                                  <i>
                                    Merci de mettre le numéro personnel du
                                    parent s’il est Sans emploi ou Retraité
                                  </i>
                                )) ||
                                (error['0'] &&
                                  error['0']['step2'] &&
                                  error['0']['step2'][index] &&
                                  error['0']['step2'][index][
                                    'data.company_address'
                                  ])
                              }
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom={false}
                      className={classes.heading}
                    >
                      Cursus
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step3'] &&
                        error['0']['step3']['data.entrance_grade']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step3'] &&
                        error['0']['step3']['data.entrance_grade']
                      }
                    >
                      <InputLabel id="gradeLabel">Niveau*</InputLabel>
                      <Select
                        labelId="gradeLabel"
                        id="grade"
                        value={grade}
                        onChange={(e) => {
                          setGrade(e.target.value);
                          setCursus('');
                        }}
                        label="Niveau"
                      >
                        {grades.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.entrance_grade']}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {Number.isInteger(grade) ? (
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.cursus']
                        }
                        helperText={
                          error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.cursus']
                        }
                      >
                        <InputLabel id="cursusLabel">Cursus*</InputLabel>
                        <Select
                          labelId="cursusLabel"
                          id="cursus"
                          value={cursus}
                          onChange={(e) => setCursus(e.target.value)}
                          label="Cursus"
                        >
                          {Array.isArray(cursuses[grade]) &&
                            cursuses[grade].map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {error['0'] &&
                            error['0']['step3'] &&
                            error['0']['step3']['data.cursus']}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {Number.isInteger(cursus) && Number(grade) === 1 && (
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.examSession']
                        }
                        helperText={
                          error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.examSession']
                        }
                      >
                        <InputLabel id="cursusLabel">
                          Session de concours*
                        </InputLabel>
                        <Select
                          labelId="examSessionLabel"
                          id="examSession"
                          value={examSession}
                          onChange={(e) => setExamSession(e.target.value)}
                          label="Session du concours"
                        >
                          {Array.isArray(examSessionOptions) &&
                            examSessionOptions.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {error['0'] &&
                            error['0']['step3'] &&
                            error['0']['step3']['data.examSession']}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        error['0'] &&
                        error['0']['step3'] &&
                        error['0']['step3']['data.school_year']
                      }
                      helperText={
                        error['0'] &&
                        error['0']['step3'] &&
                        error['0']['step3']['data.school_year']
                      }
                    >
                      <InputLabel id="cursusLabel">Année scolaire*</InputLabel>
                      <Select
                        labelId="examSessionLabel"
                        id="schoolYear"
                        value={school_year}
                        onChange={(e) => setSchoolYear(e.target.value)}
                        label="Session du concours"
                      >
                        {Array.isArray(schoolYears) &&
                          schoolYears.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        {error['0'] &&
                          error['0']['step3'] &&
                          error['0']['step3']['data.school_year']}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </>
          )}

          {Object.keys(error).length !== 0 &&
          error['0'] &&
          Object.keys(error['0']).length !== 0 &&
          !error['0']['step1'] &&
          !error['0']['step2'] &&
          !error['0']['step3'] ? (
            <>
              <Grid item xs={12}>
                {' '}
              </Grid>
              <Grid item xs={12}>
                <Alert severity="error">{error['0']}</Alert>
              </Grid>
            </>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.saveButton}
          >
            Enregistrer
            {saving && (
              <Box className={classes.progress}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={16}
                />
              </Box>
            )}
          </Button>
        </CardActions>
      </Card>
    </Create>
  );
};

export default ApplicantCreate;
