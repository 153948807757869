import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useRef } from 'react';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const DocumentViewer = ({ path }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const documentRef = useRef();

  useEffect(() => {
    setLoading(true);
  }, [path]);

  return (
    <div className={classes.root}>
      <iframe
        ref={documentRef}
        width="100%"
        height={600}
        title="Doc viewer"
        frameBorder="none"
        src={`https://docs.google.com/gview?url=${path}&embedded=true`}
        onLoad={() => setLoading(false)}
      ></iframe>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

export default DocumentViewer;
