import React, { createRef } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

const FileInput = (props) => {
  const { filename, ...rest } = props;

  const inputRef = createRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={props.disabled}
          onClick={handleClick}
          size="large"
        >
          Ajouter un fichier
        </Button>
      </Grid>
      <Grid item xs>
        <Typography>{filename || 'Aucun fichier choisi'}</Typography>
      </Grid>

      <input
        ref={inputRef}
        type="file"
        {...rest}
        hidden
        accept="image/*, .pdf"
      />
    </Grid>
  );
};

export default FileInput;
