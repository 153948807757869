import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { Save } from '@material-ui/icons';
import { AdminApi } from '../utils/Api';
import { makeStyles } from '@material-ui/core/styles';
import FileInput from './FileInput';

const useStyles = makeStyles((theme) => ({
  saveButton: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
}));

const DocumentDetailsDialog = ({
  open,
  onClose,
  applicantId,
  isUpdate,
  document: initialDocument,
}) => {
  const [document, setDocument] = useState(() => {
    if (initialDocument) return initialDocument;

    return {
      type: '',
      document: '',
      file_name: '',
      mime_type: '',
    };
  });

  const [saving, setSaving] = useState(false);

  const [error, setError] = useState();

  const notify = useNotify();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setDocument({
      ...document,
      [name]: value,
    });
  };

  const onFileChange = (event) => {
    event.persist();

    const reader = new FileReader();

    reader.onload = () => {
      const b64 = window.btoa(reader.result);
      setDocument({
        ...document,
        document: b64,
        file_name: event.target.files[0].name,
        mime_type: event.target.files[0].type,
      });
    };

    reader.readAsBinaryString(event.target.files[0]);
  };

  const save = async () => {
    setSaving(true);
    setError();
    try {
      await AdminApi.put(`/applicant/${applicantId}/document`, document);
      notify(`Document ${isUpdate ? 'modifié' : 'ajouté'} avec succès`);
      close();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.response?.data && error.response?.data[0]);
    } finally {
      setSaving(false);
    }
  };

  const close = () => {
    onClose();
    setError();
    setDocument({});
  };

  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={close}>
      <DialogTitle id="add-document-dialog-title">
        {!isUpdate ? 'Ajouter un document' : 'Modifier un document'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={error && error['data.type']}
            >
              <InputLabel id="document-type-label">Type de document</InputLabel>
              <Select
                labelId="document-type-label"
                name="type"
                value={document.type}
                onChange={handleChange}
                label="Type de document"
              >
                <MenuItem value="" disabled>
                  <em>Veuillez choisir un type de document</em>
                </MenuItem>
                <MenuItem value={100}>CIN</MenuItem>
                <MenuItem value={110}>Acte de naissance</MenuItem>
                <MenuItem value={120}>Passeport</MenuItem>
                <MenuItem value={130}>Relevé de notes</MenuItem>
                <MenuItem value={140}>CV</MenuItem>
                <MenuItem value={150}>Photo d'identité</MenuItem>
                <MenuItem value={160}>
                  Attestation d'emploi ou Certificat de travail*
                </MenuItem>
                <MenuItem value={170}>Diplôme</MenuItem>
                <MenuItem value={190}>
                  Bordereau de versement à la banque du droit de concours
                </MenuItem>
                <MenuItem value={200}>Certificat de résidence</MenuItem>
                <MenuItem value={210}>
                  Certificat de résidence des parents/tuteurs
                </MenuItem>
                <MenuItem value={230}></MenuItem>
              </Select>
              {error && error['data.type'] && (
                <FormHelperText>{error['data.type']}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {(Number(document.type) === 100 || Number(document.type) === 120) && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="number"
                  label="N° CIN*"
                  value={document.number}
                  onChange={handleChange}
                  error={error && error['data.number']}
                  helperText={error && error['data.number']}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="delivery_date"
                  label="Délivré le*"
                  value={document.delivery_date}
                  onChange={handleChange}
                  error={error && error['data.delivery_date']}
                  helperText={error && error['data.delivery_date']}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="delivery_place"
                  label="Délivré à*"
                  value={document.delivery_place}
                  onChange={handleChange}
                  error={error && error['data.delivery_place']}
                  helperText={error && error['data.delivery_place']}
                />
              </Grid>
            </>
          )}
          {Number(document.type) === 120 && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="expiry_date"
                label="Expiré le*"
                value={document.expiry_date}
                onChange={handleChange}
                error={error && error['data.expiry_date']}
                helperText={error && error['data.expiry_date']}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FileInput filename={document.file_name} onChange={onFileChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Annuler
        </Button>

        <Button
          className={classes.saveButton}
          variant="contained"
          startIcon={<Save />}
          onClick={save}
          color="secondary"
          autoFocus
        >
          Sauvegarder
          {saving && (
            <Box className={classes.progress}>
              <CircularProgress
                variant="indeterminate"
                color="inherit"
                size={16}
              />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDetailsDialog;
