import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url, options = {}) => {
  if (!options.headers)
    options.headers = new Headers({ Accept: 'application/json' });

  const token = sessionStorage.getItem('X-AUTH-TOKEN');
  options.headers.set('X-AUTH-TOKEN', token);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_ADMIN_URL,
  httpClient
);

export default dataProvider;
